var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"pagination-options":{
              enabled: true,
              mode: 'records',
              position: 'top',
              perPageDropdown: [ 10, 20, 50],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: 'Selanjutnya',
              prevLabel: 'Sebelumnya',
              rowsPerPageLabel: 'Jumlah baris',
              ofLabel: 'dari',
              pageLabel: 'halaman', // for 'pages' mode
              allLabel: 'Semua',
            }},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'success')?_c('span',[(props.row.success)?_c('b-badge',{attrs:{"pill":"","variant":"success"}},[_c('i',{staticClass:"las la-check"})]):_c('b-badge',{attrs:{"pill":"","variant":"success"}},[_c('i',{staticClass:"las la-times"})])],1):(props.column.field === 'no_spt')?_c('span',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.loadSpt(props)}}},[_vm._v(_vm._s(props.row.no_spt))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title mb-0"},[_vm._v("SPT Log")])])])
}]

export { render, staticRenderFns }