<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">SPT Log</h4>
            </div>
          </div>
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :line-numbers="true"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                position: 'top',
                perPageDropdown: [ 10, 20, 50],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'Selanjutnya',
                prevLabel: 'Sebelumnya',
                rowsPerPageLabel: 'Jumlah baris',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
            >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'success'">
                  <b-badge v-if="props.row.success" pill variant="success"><i class="las la-check"></i></b-badge>
                  <b-badge v-else pill variant="success"><i class="las la-times"></i></b-badge>
                </span>
                <span v-else-if="props.column.field === 'no_spt'">
                  <a href="javascript:void(0)" @click="loadSpt(props)">{{ props.row.no_spt }}</a>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-good-table/dist/vue-good-table.css'
  import { getDataService } from '../../store/modules/crudservices'
  import { VueGoodTable } from 'vue-good-table'

  export default {
    name:'SPTList',
    data () {
      return {
        isLoading: false,
        totalRecords: 0,
        serverParams: {
          columnFilters: [],
          sort: [],
          page: 1, 
          perPage: 10
        },
        modalTitle: '',
        lblAnggaran: '',
        columns:[{
            label: 'Nama',
            field: 'username',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              trigger: 'enter',
            },
          }, {
            label: 'No. SPT',
            field: 'no_spt',
            filterOptions: {
              styleClass: 'class2', 
              enabled: true,
              trigger: 'enter',
            },
          }, {
            label: 'Aksi',
            field: 'aksi'
          }, {
            label: 'Sukses',
            field: 'success',
            tdClass: 'text-center',
          }, {
            label: 'Detail',
            field: 'Detail'
          },  {
            label: 'Tanggal',
            field: 'tanggal'
          }
        ],
        rows: [],
      }
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      
      onPageChange(params) {
        this.updateParams({page: params.currentPage});
        this.loadItems();
      },

      onPerPageChange(params) {
        this.updateParams({perPage: params.currentPerPage});
        this.loadItems();
      },

      onSortChange(params) {
        const tes = params.map((item)=> {
          return {
            'type': item.type,
            'field': item.field
          }
        })
        this.updateParams({
          sort: tes,
        });
        this.loadItems();
      },
      
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },

      // load items is what brings back the rows from server
      loadItems() {
        getDataService('/spt-log', this.serverParams).then(data => {
          this.totalRecords = data.data.total
          this.rows = data.data.data
        })
      },
      loadSpt(props) {
        this.$router.push(`/spt/${props.row.reference_id}/sppd`)
      }
    },
    components: {
      VueGoodTable,
    },
    mounted() {
      this.loadItems()
    }
  }
</script>